.btn {
    font-family: "Ubuntu", sans-serif;
    position: relative;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2;
    height: 50px;
    transition: all 200ms linear;
    border-radius: 4px;
    width: 240px;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-self: center;
    border: none;
    cursor: pointer;
    background-color: var(--primaryColor);
    color: var(--secondaryColor);
    fill: var(--secondaryColor);
    box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
    outline: 0;
    text-transform: capitalize;
  }
  
  .btn:hover {
    background-color: var(--secondaryColor);
    color: var(--primaryColor);
    fill: var(--primaryColor);
  }
  