
.skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
  }
  
  .skills .progress .skill {
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-display: swap;
    color: #050d18;
  }
  
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
  }
  
  .skills .progress-bar-wrap {
    background: #dce8f8;
    height: 10px;
  }
  
  .skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: 0.9s;
    background-color: #149ddd;
  }
  