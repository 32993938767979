.carousel {
    height: 460px;
    max-height: 460px;
  }

 .carousel-inner img {
    max-height: 400px;
 } 

 .slide{text-align: center;margin:30px 0px; border-radius: 4px; background-color: rgb(245, 242, 242);}

 .carousel-indicators{margin-bottom: 0rem;}
 .carousel-indicators button{background-color:#149ddd !important}
 .carousel-control-prev-icon{background-color:#149ddd !important}
 .carousel-control-next-icon{background-color:#149ddd !important}