@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?s90xtj');
  src:  url('fonts/icomoon.eot?s90xtj#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?s90xtj') format('truetype'),
    url('fonts/icomoon.woff?s90xtj') format('woff'),
    url('fonts/icomoon.svg?s90xtj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-quote-left:before {
  content: "\e911";
}
.icon-quote-right:before {
  content: "\e912";
}
.icon-file-pdf:before {
  content: "\e90e";
}
.icon-angle-left:before {
  content: "\e90f";
}
.icon-angle-right:before {
  content: "\e910";
}
.icon-home:before {
  content: "\e900";
}
.icon-file-blank:before {
  content: "\e901";
}
.icon-envelope:before {
  content: "\e902";
}
.icon-news:before {
  content: "\e903";
}
.icon-server:before {
  content: "\e904";
}
.icon-sidebar:before {
  content: "\e905";
}
.icon-printer:before {
  content: "\e906";
}
.icon-menu:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-up-arrow:before {
  content: "\e909";
}
.icon-telegram:before {
  content: "\e90a";
}
.icon-skype:before {
  content: "\e90b";
}
.icon-gmail:before {
  content: "\e90c";
}
.icon-print:before {
  content: "\e90d";
}
