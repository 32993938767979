#footer {
    padding: 15px;
    color: #f4f6fd;
    font-size: 14px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 99;
    background: #040b14;
  }
  
  #footer .copyright {
    text-align: center;
  }
  
  #footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #eaebf0;
  }
  
  @media (max-width: 1199px) {
    #footer {
      position: static;
      width: auto;
      padding-right: 20px 15px;
    }
  }